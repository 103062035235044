import React from "react"

function loadingPage() {
    return (
        <div>
            <h1>Loading ... </h1>
        </div>
    )
}

export default loadingPage