import React from "react";


export default function Header(post) {


  return (
      <header>
            <h1 id="artistName">{post.ArtistName}</h1>
      </header>
    );
  }

  